import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';

import Styles from './Styles.module.scss';
import { Button, ButtonType } from '@/components/form-controls/button';
import CloseIcon from '@/assets/icons/CloseIcon';
import { TranslationService } from '@/services/translation';
import { EventModel } from '@/events/models';
import { Indicators } from '@/events/components/nationwide-events-list/indicators';
import { eventsActions } from '@/events/reducer';
import { RegistrationStatus } from '@/events/reducer/types';
import { Analytics } from '@/services/analytics';
import { AnalyticsEventCategory, AnalyticsEventName } from '@/services/analytics/types';
import { useDispatch } from 'react-redux';
import { EventDetailsImage } from '@/events/components/event-details-image';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    message: string;
    otherEvents: EventModel[];
};

export const ErrorModal: FunctionComponent<Props> = ({
    isOpen,
    onClose,
    title,
    message,
    otherEvents,
}) => {
    const [eventIndex, setEventIndex] = useState(0);

    const dispatch = useDispatch();

    const onSelectedEvent = useCallback(
        (event: EventModel) => {
            const newIndex = otherEvents.findIndex((e) => e.id === event.id);
            setEventIndex(newIndex);
        },
        [otherEvents]
    );

    const onSelectEventIndex = useCallback(
        (selectedEventIndex: number) => setEventIndex(selectedEventIndex),
        []
    );

    const goToPreviousEvent = useCallback(() => {
        if (otherEvents.length === 0 || eventIndex === 0) {
            return;
        }

        setEventIndex(eventIndex - 1);
    }, [otherEvents, eventIndex]);

    const goToNextEvent = useCallback(() => {
        if (otherEvents.length === 0 || eventIndex === otherEvents.length - 1) {
            return;
        }

        setEventIndex(eventIndex + 1);
    }, [otherEvents, eventIndex]);

    const onDisplayDetails = useCallback(
        (event: EventModel) => {
            dispatch(eventsActions.selectEvent(event));
            dispatch(eventsActions.updateRegistrationStatus(RegistrationStatus.VIEWING_DETAILS));

            Analytics.getInstance().fireEvent(
                AnalyticsEventName.ViewDetailsButtonClick,
                AnalyticsEventCategory.EventRegistration,
                event.name,
                {
                    event_id: event.id,
                }
            );

            onClose();
        },
        [dispatch, onClose]
    );

    return (
        <Modal className={Styles.ErrorModal} centered={true} show={isOpen} onHide={onClose}>
            <Modal.Body>
                <Row className={'pb-2 mx-0 w-100'}>
                    <Col className={`${Styles.Header} d-flex p-0`}>
                        <div
                            className={`${Styles.Title} d-flex justify-content-center align-items-end`}
                        >
                            {title}
                        </div>

                        <div onClick={onClose} className="cursor-pointer">
                            <CloseIcon />
                        </div>
                    </Col>
                </Row>

                <Row className={'pb-4 pt-2'}>
                    <Col className={`py-0 d-flex`}>
                        <span className={`${Styles.Paragraph} text-center`}>{message}</span>
                    </Col>
                </Row>

                <Row className={`${Styles.NationwideEventsListCarousel} mx-0 pb-4 mb-3`}>
                    <Col className={'px-3'}>
                        <Carousel
                            activeIndex={eventIndex}
                            onSelect={onSelectEventIndex}
                            indicators={false}
                            controls={false}
                        >
                            {otherEvents.map((event) => (
                                <Carousel.Item
                                    key={event.id}
                                    onClick={() => onDisplayDetails(event)}
                                >
                                    <EventDetailsImage event={event} />

                                    <div className={`${Styles.Title} d-flex align-items-end pt-3`}>
                                        {event.name}
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>

                        <Indicators
                            events={otherEvents}
                            onSelectedEvent={onSelectedEvent}
                            selectedEvent={otherEvents[eventIndex]}
                            onPreviousEvent={goToPreviousEvent}
                            onNextEvent={goToNextEvent}
                        />
                    </Col>
                </Row>

                <Row className={'pt-2 mb-4'}>
                    <Col className={'p-0 d-flex justify-content-center'}>
                        <Button
                            type={ButtonType.PRIMARY_BLUE}
                            onClick={onClose}
                            customClassName={`px-3 py-2 text-nowrap text-normal`}
                        >
                            {TranslationService.getInstance().getPhrase(
                                'registration.modals.restrictions.not_in_health_plan.button'
                            )}
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};
