import React, {
    ChangeEvent,
    FunctionComponent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import Styles from './Styles.module.scss';
import { EventModel } from '@/events/models';
import { VenueType } from '@/venues/models';
import { MapView } from '@/events/components/map-view';
import { EventLocation } from '@/events/components/event-location';
import { EventTime } from '@/events/components/event-time';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { AuthenticatedUserModel } from '@/authentication/models';
import { EventDetailsMiscellaneous } from '@/events/components/event-details-miscellaneous';
import { OtherEvents } from '@/events/components/other-events';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';
import { TranslationService } from '@/services/translation';
import { Button, ButtonType } from '@/components/form-controls/button';
import { IMAGES } from '@/assets/images';
import { Link, LinkType } from '@/components/form-controls/link';
import { InputBox } from '@/components/form-controls/input-v2';
import { UpdateCommunicationPreferenceRequest } from '@/events/api/types';
import { updateCommunicationPreferences } from '@/events/api';
import { isTokenValid } from '@/authentication/api';
import { authenticationActions } from '@/authentication/reducer';
import { ShareModal } from '@/events/components/share-modal';

type Props = {
    handleSelectionOfEvent: (event: EventModel) => void;
    containerRef: any;
    event: EventModel;
};

export const AfterConfirmationStatus: FunctionComponent<Props> = ({
    handleSelectionOfEvent,
    containerRef,
    event,
}) => {
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();

    const [mobileScreen, setMobileScreen] = useState(false);
    const [isFirstTimeLoad, setFirstTimeLoad] = useState(true);

    const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined);
    const [phoneNumberDisabled, setPhoneNumberDisabled] = useState(true);
    const [phoneNumberChecked, setPhoneNumberChecked] = useState(true);

    const [email, setEmail] = useState<string | undefined>(undefined);
    const [emailDisabled, setEmailDisabled] = useState(true);
    const [emailChecked, setEmailChecked] = useState(true);

    const [loading, setLoading] = useState(false);
    const [isToastShown, showToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastTitle, setToastTitle] = useState('');

    const toastRef = useRef(null);

    const selectedEvent = useSelector<RootState, EventModel | undefined>(
        (state) => state.events.selectedEvent
    );

    const authenticatedUser = useSelector<RootState, AuthenticatedUserModel | undefined>(
        (state) => state.authentication.authenticatedUser
    );

    const disabledUpdateButton = useMemo(() => {
        return (
            loading ||
            (emailChecked === authenticatedUser?.canItBeContactedThroughEmail &&
                phoneNumberChecked === authenticatedUser?.canItBeContactedThroughPhone &&
                email === (authenticatedUser.customEmail || authenticatedUser.email) &&
                phoneNumber === (authenticatedUser.customPhone || authenticatedUser.phone))
        );
    }, [
        loading,
        emailChecked,
        authenticatedUser?.canItBeContactedThroughEmail,
        authenticatedUser?.canItBeContactedThroughPhone,
        authenticatedUser?.customEmail,
        authenticatedUser?.email,
        authenticatedUser?.customPhone,
        authenticatedUser?.phone,
        phoneNumberChecked,
        email,
        phoneNumber,
    ]);

    const handleResize = useCallback(() => {
        setMobileScreen(width < 568);
    }, [width, setMobileScreen]);

    const onEnablePhoneNumberInput = useCallback(
        () => setPhoneNumberDisabled(!phoneNumberDisabled),
        [setPhoneNumberDisabled, phoneNumberDisabled]
    );

    const onPhoneNumberCheckboxChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setPhoneNumberChecked(!phoneNumberChecked);
        },
        [phoneNumberChecked]
    );

    const onEnableEmailInput = useCallback(
        () => setEmailDisabled(!emailDisabled),
        [setEmailDisabled, emailDisabled]
    );

    const onEmailCheckboxChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setEmailChecked(!emailChecked);
        },
        [setEmailChecked, emailChecked]
    );

    const updateContact = useCallback(async () => {
        setLoading(true);

        const body: UpdateCommunicationPreferenceRequest = {
            event_id: event.id,
            keep_updated_email: emailChecked,
            keep_updated_text: phoneNumberChecked,
            phone: phoneNumber,
            email,
        };

        try {
            await updateCommunicationPreferences(body);
            const updatedAuthenticatedUser = await isTokenValid();
            dispatch(authenticationActions.storeAuthenticatedUser(updatedAuthenticatedUser));

            setPhoneNumberDisabled(true);
            setEmailDisabled(true);
            setToastMessage('Contact has been updated successfully');
            setToastTitle('Success');
        } catch {
            setToastMessage('Contact could not be updated');
            setToastTitle('Ooops');
        }

        showToast(true);
        setLoading(false);
    }, [
        event.id,
        emailChecked,
        phoneNumberChecked,
        phoneNumber,
        email,
        dispatch,
        toastRef?.current,
    ]);

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    useEffect(() => {
        if (authenticatedUser) {
            setPhoneNumber(authenticatedUser.customPhone || authenticatedUser.phone);
            setEmail(authenticatedUser.customEmail || authenticatedUser.email);
        }
    }, [authenticatedUser, setFirstTimeLoad, isFirstTimeLoad]);

    useEffect(() => {
        if (authenticatedUser && !emailChecked) {
            setEmail(authenticatedUser.customEmail || authenticatedUser.email);
            setEmailDisabled(true);
        }

        if (authenticatedUser && !phoneNumberChecked) {
            setPhoneNumber(authenticatedUser.customPhone || authenticatedUser.phone);
            setPhoneNumberDisabled(true);
        }
    }, [authenticatedUser, emailChecked, phoneNumberChecked]);

    return (
        <div className={`${Styles.AfterConfirmationStatus}`}>
            {isToastShown && (
                <ShareModal
                    isOpen={isToastShown}
                    onClose={() => showToast(false)}
                    message={toastMessage}
                    title={toastTitle}
                />
            )}

            <div className={`p-0 d-block mt-3`}>
                {selectedEvent?.venue.type === VenueType.IN_PERSON ? (
                    <div className={`w-100 mb-3 ${Styles.EventMapContainer}`}>
                        {selectedEvent && (
                            <MapView
                                events={[selectedEvent]}
                                authenticatedUser={authenticatedUser}
                                onViewDetails={() => {}}
                                onSelectedEvents={() => {}}
                                areEventsClickable={false}
                                customClassname={Styles.CustomInnerMapClass}
                                loadingEvents={false}
                                recordBoundaries={false}
                                showFirstEventLocationOnly
                            />
                        )}
                    </div>
                ) : null}

                <h4 className={`px-0 pb-2 ${Styles.EventTitle}`}>{selectedEvent?.name}</h4>

                <div className={'d-xl-block d-lg-block d-md-block d-sm-none d-none w-100 py-2'} />

                <EventLocation
                    event={selectedEvent as EventModel}
                    isAuthenticated={!!authenticatedUser}
                />

                <EventTime event={selectedEvent as EventModel} />

                <div
                    className={'d-xl-block d-lg-block d-md-block d-sm-none d-none w-100 pb-4 pt-2'}
                >
                    <div className={Styles.DividerFull} />
                </div>
            </div>

            <EventDetailsMiscellaneous event={selectedEvent as EventModel} />

            <div className={`${Styles.DividerFull} my-4`} />

            <div
                ref={containerRef}
                className={`p-0 d-flex flex-column justify-content-center ${Styles.DetailsSectionRight}`}
            >
                <div className={`${Styles.ItemTitleRow} d-flex flex-row`}>
                    <div className={`${Styles.ItemNumb}`}>
                        <span>1</span>
                    </div>

                    <div className={'pt-2'}>
                        <h2 className={Styles.EventTitle}>
                            {TranslationService.getInstance().getPhrase(
                                'registration.after_registration.what_is_next.step_1.title'
                            )}
                        </h2>

                        <p>
                            {TranslationService.getInstance().getPhrase(
                                'registration.after_registration.what_is_next.step_1.paragraph'
                            )}
                        </p>
                        <div className="d-flex gap-3 align-items-start pt-4 mt-2">
                            <input
                                type="checkbox"
                                className={`${Styles.FormCheckBox}`}
                                checked={emailChecked}
                                onChange={onEmailCheckboxChange}
                            />

                            <div className={'d-flex flex-column w-100'}>
                                <span className="d-block">
                                    {'Please keep me updated via email'}
                                    {emailChecked && (
                                        <>
                                            {' (No email. '}
                                            <a
                                                href="#"
                                                className="text-main-primary"
                                                onClick={onEnableEmailInput}
                                            >
                                                Add email here
                                            </a>
                                            {')'}
                                        </>
                                    )}
                                </span>
                                <span className="d-block mt-2 mb-2 fw-semibold">
                                    Email for reminders
                                </span>
                                <span className="d-block">
                                    <InputBox
                                        label={''}
                                        type={'email'}
                                        value={email}
                                        placeholder="abc@email.com"
                                        onChange={(newValue) => setEmail(newValue)}
                                        isValid={true}
                                        uiOptions={{
                                            containerHeight: 50,
                                            containerWidth: '80%',
                                        }}
                                        disabled={emailDisabled}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="d-flex gap-3 align-items-start mt-4">
                            <input
                                type="checkbox"
                                className={`${Styles.FormCheckBox}`}
                                checked={phoneNumberChecked}
                                onChange={onPhoneNumberCheckboxChange}
                            />
                            <div className={'d-flex flex-column w-100'}>
                                <span className="d-block">
                                    {`Please keep me updated via text message to ${authenticatedUser?.customPhone || authenticatedUser?.phone}`}

                                    {phoneNumberChecked && (
                                        <>
                                            {' ('}
                                            <a
                                                href="#"
                                                className="text-main-primary"
                                                onClick={onEnablePhoneNumberInput}
                                            >
                                                Edit
                                            </a>
                                            {')'}
                                        </>
                                    )}
                                </span>
                                <span className="d-block mt-2 mb-2 fw-semibold">
                                    The number we text
                                </span>
                                <span className="d-block">
                                    <InputBox
                                        label={''}
                                        type={'tel'}
                                        value={phoneNumber}
                                        placeholder="000 000 0000"
                                        onChange={(newValue) => setPhoneNumber(newValue)}
                                        isValid={true}
                                        uiOptions={{
                                            containerHeight: 50,
                                            containerWidth: '80%',
                                        }}
                                        disabled={phoneNumberDisabled}
                                    />
                                </span>

                                <div
                                    className={`${Styles.ButtonRow} justify-content-end align-items-end pt-3`}
                                >
                                    <Button
                                        type={ButtonType.PRIMARY_BLUE}
                                        onClick={updateContact}
                                        customClassName={`w-auto ${Styles.UpdateButton}`}
                                        disabled={disabledUpdateButton}
                                    >
                                        <div className={`text-center`}>
                                            {TranslationService.getInstance().getPhrase(
                                                'registration.after_registration.what_is_next.step_1.button.label'
                                            )}
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${Styles.ItemTitleRow} d-flex flex-row py-4`}>
                    <div className={`${Styles.ItemNumb}`}>
                        <span>2</span>
                    </div>
                    <div className={'pt-2'}>
                        <h2 className={`pb-1 ${Styles.EventTitle}`}>
                            {TranslationService.getInstance().getPhrase(
                                'registration.after_registration.what_is_next.step_2.title'
                            )}
                        </h2>

                        <p className="text-base">
                            {TranslationService.getInstance().getPhrase(
                                'registration.after_registration.what_is_next.step_2.paragraph'
                            )}
                        </p>
                        <div className={`${Styles.ButtonRow} d-flex pt-4`}>
                            <div className={Styles.LogoCon}>
                                <img
                                    src={IMAGES.LogoSquare}
                                    className={Styles.MemberAppLogo}
                                    alt="Wider Circle"
                                />
                                {!mobileScreen ? (
                                    <h4>
                                        Connect For Life
                                        <br />
                                        App
                                    </h4>
                                ) : (
                                    <h4>Connect For Life App</h4>
                                )}
                            </div>
                            <Link
                                type={LinkType.PRIMARY_BLUE_OUTLINE}
                                customClassName={Styles.DownloadButton}
                                href={
                                    'https://www.widercircle.com/wp-content/uploads/2022/12/WC-member-app-flier-12_05_22-FINAL.pdf'
                                }
                                target={'_blank'}
                            >
                                <div className={`text-center`}>
                                    {TranslationService.getInstance().getPhrase(
                                        'registration.after_registration.what_is_next.step_2.download_button'
                                    )}
                                </div>
                            </Link>
                            <Link
                                type={LinkType.PRIMARY_BLUE_OUTLINE}
                                customClassName={Styles.DownloadButton}
                                href={'https://www.widercircle.com/memberapp/'}
                                target={'_blank'}
                            >
                                Download App
                            </Link>
                        </div>
                        <div className={`${Styles.PromotionalVideo}`}>
                            <iframe
                                width="100%"
                                height="450"
                                src="https://www.youtube.com/embed/aWujK4CCT3w"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen={true}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${Styles.DividerFull} my-4`} />

            <OtherEvents handleSelectionOfEvent={handleSelectionOfEvent} />
        </div>
    );
};
