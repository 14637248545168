import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import Styles from './Styles.module.scss';
import { EventModel, EventType } from '@/events/models';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, ButtonType } from '@/components/form-controls/button';
import { IMAGES } from '@/assets/images';
import { DateModel } from '@/utilities/dates';
import { VenueType } from '@/venues/models';
import { EventBadges } from '@/events/components/event-badges';
import { getRegistrationButtonLabel } from '@/events/pages/all-events/utils';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { AuthenticatedUserModel } from '@/authentication/models';
import { TranslationService } from '@/services/translation';

type Props = {
    events: EventModel[];
    onClose: () => void;
    isOpen: boolean;
    onDisplayDetails: (event: EventModel) => void;
};

export const EventsListMobileModal: FunctionComponent<Props> = ({
    events,
    onClose,
    onDisplayDetails,
    isOpen,
}) => {
    const [eventSelected, setEventSelected] = useState<EventModel>(events[0]);

    const [isNextButtonShown, showNextButton] = useState<boolean>(true);
    const authenticatedUser = useSelector<RootState, AuthenticatedUserModel | undefined>(
        (state) => state.authentication.authenticatedUser
    );

    const numberOfEvents = useMemo(() => events.length, [events]);

    const closeHandler = useCallback(() => {
        onClose();
    }, [onClose]);

    const nextClickHandler = useCallback(() => {
        const index = events.findIndex((event) => event.id === eventSelected.id);

        if (index + 1 < events.length) {
            setEventSelected(events[index + 1]);
        }

        if (index + 1 === events.length) {
            setEventSelected(events[0]);
        }
    }, [eventSelected, setEventSelected, events]);

    const displayDetailsHandler = useCallback(() => {
        onDisplayDetails(eventSelected);
    }, [eventSelected, onDisplayDetails]);

    useEffect(() => {
        if (events.length > 0 && eventSelected) {
            const updatedEventSelected = events.find((event) => event.id === eventSelected.id);

            if (updatedEventSelected) {
                setEventSelected(updatedEventSelected);
            }
        }
    }, [events, eventSelected]);

    return (
        <Modal
            show={isOpen}
            onHide={onClose}
            size="lg"
            dialogClassName={Styles.EventsListMobileModal}
            contentClassName={Styles.EventsListMobileModalContent}
        >
            {events.length > 1 && (
                <Modal.Header className={`px-2`}>
                    <Row className={`mx-0 w-100 ${Styles.Header}`}>
                        <Col className={'d-flex justify-content-between align-items-center'}>
                            <div
                                className={`${Styles.TitleContainer} d-flex align-items-center px-4 py-2`}
                            >
                                <img
                                    alt="info-circle-transparent-icon"
                                    src={IMAGES.InfoCircleBlueIcon}
                                />

                                <span
                                    className={`${Styles.Title} ps-2`}
                                >{`${numberOfEvents} Events at this location`}</span>
                            </div>

                            {isNextButtonShown && (
                                <Button
                                    type={ButtonType.LINK_BUTTON}
                                    onClick={nextClickHandler}
                                    customClassName={`px-3 py-2 ${Styles.CloseButton} d-flex align-items-center`}
                                >
                                    <span className={'pe-2'}>Next</span>

                                    <img
                                        alt="line-arrow-right-icon"
                                        src={IMAGES.LineArrowRightIcon}
                                    />
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Modal.Header>
            )}

            <Modal.Body className={`p-0 ${Styles.EventsListMobileModalBody}`}>
                <Row className={`mx-0 ${Styles.Body}`}>
                    <Col className={`p-3 ${Styles.SelectedEventInformation}`}>
                        <EventBadges event={eventSelected} />

                        <Row className={'pt-2 pb-3 mx-0'}>
                            <Col className={'px-1'}>
                                <span className={Styles.Title}>{eventSelected.name}</span>
                            </Col>
                        </Row>

                        {eventSelected.description && (
                            <Row className={'pb-3 mx-0'}>
                                <Col className={'px-1'}>
                                    <span className={Styles.Description}>
                                        {eventSelected.description}
                                    </span>
                                </Col>
                            </Row>
                        )}

                        {authenticatedUser && (
                            <Row className={'pb-1 mx-0'}>
                                <Col className={'px-1'}>
                                    {eventSelected.venue.type === VenueType.IN_PERSON ? (
                                        <img
                                            alt="direction-sign-icon"
                                            className={`pe-2 ${Styles.DirectionSignIcon}`}
                                            src={IMAGES.DirectionSignIcon}
                                        />
                                    ) : (
                                        <img
                                            alt="headset-icon"
                                            className={`pe-2 ${Styles.Icon}`}
                                            src={IMAGES.HeadsetIcon}
                                        />
                                    )}

                                    <span className={Styles.Location}>
                                        {eventSelected.venue.name}
                                    </span>
                                </Col>
                            </Row>
                        )}

                        {!authenticatedUser && (
                            <Row className={'pt-2 pb-1 mx-0'}>
                                <Col className={'px-1 d-flex align-items-center'}>
                                    <div className={`${Styles.SignInBadge} px-2`}>
                                        {TranslationService.getInstance().getPhrase(
                                            'events_list.badges.sign_in'
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        )}

                        <Row className={'pb-1 mx-0'}>
                            <Col className={'px-1'}>
                                <img
                                    alt="map-location-icon"
                                    className={`pe-2 ${Styles.Icon}`}
                                    src={IMAGES.MapLocationIcon}
                                />
                                <span
                                    className={Styles.Location}
                                >{`${eventSelected.venue.city} ${eventSelected.venue.state} ${eventSelected.venue.zipcode}`}</span>
                            </Col>
                        </Row>

                        {authenticatedUser && (
                            <Row className={'pb-1 mx-0'}>
                                <Col className={'px-1 d-flex align-items-center'}>
                                    <img
                                        alt="calendar-icon"
                                        className={`pe-2 ${Styles.CalendarIcon}`}
                                        src={IMAGES.CalendarIcon}
                                    />
                                    <span className={Styles.Details}>{`${new DateModel(
                                        eventSelected.startDateInMilliseconds
                                    ).format('MMMM DD')} at ${new DateModel(
                                        eventSelected.startDateInMilliseconds
                                    ).format('LT')} to ${new DateModel(
                                        eventSelected.endDateInMilliseconds
                                    ).format(
                                        'LT'
                                    )} (${new DateModel(eventSelected.endDateInMilliseconds).format('z')})`}</span>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Row className={`${Styles.Actions} mx-0 w-100`}>
                    <Col className={`d-flex align-items-center justify-content-between pb-3`}>
                        <Button
                            type={ButtonType.LINK_BUTTON}
                            onClick={closeHandler}
                            customClassName={`px-4 py-2 ${Styles.ClearButton} h-100`}
                        >
                            Close
                        </Button>

                        <Button
                            type={ButtonType.PRIMARY_BLUE_1}
                            onClick={displayDetailsHandler}
                            customClassName={`px-4 py-2 ${Styles.SubmitButton} h-100`}
                        >
                            {getRegistrationButtonLabel(eventSelected)}
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};
