import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Bowser from 'bowser';
import { useNavigate } from 'react-router-dom';
import mustache from 'mustache';

import { EventModel } from '@/events/models';
import { EventRegistrationConnectorProps } from '@/events/pages/event-registration/connector';
import { EventRegistrationCompleteModal } from '@/events/components/event-registration-complete-modal/event-registration-complete-modal';
import { ShareModal } from '@/events/components/share-modal';
import Styles from './Styles.module.scss';
import { Config } from '@/utilities/config';
import { EventDetailsHeader } from '@/events/components/event-details-header';
import { EventDetailsActions } from '@/events/components/event-details-actions';
import { RegistrationStatus } from '@/events/reducer/types';
import { useDispatch, useSelector } from 'react-redux';
import { eventsActions } from '@/events/reducer';
import { useRegistrationOperations } from '@/events/pages/event-registration/components/use-registration-operations';
import { ViewDetailsStatus } from '@/events/components/registration-statuses/view-details-status';
import { ConfirmingStatus } from '@/events/components/registration-statuses/confirming-status';
import { AfterConfirmationStatus } from '@/events/components/registration-statuses/after-confirmation-status';
import { useContainerDimensions } from '@/hooks/use-container-dimensions';
import { TranslationService } from '@/services/translation';
import { ErrorModal } from '@/events/components/error-modal';
import { RootState } from '@/store';
import { ClientModel } from '@/clients/models';
import intersection from 'lodash/intersection';

type Props = EventRegistrationConnectorProps & {
    showBackAllEvents: () => void;
    fetchUpcomingWeeklyEvents: (params: { eventId: any; clients: any }) => any;
    openAuthenticationModal: (afterAuthenticationCallback: () => void) => void;
};

export const EventDetailsModal: FunctionComponent<Props> = ({
    showBackAllEvents,
    isLoadingEvents,
    selectedEvent,
    authenticatedUser,
    selectEvent,
    queryParameters,
    fetchedUpcomingWeeklyEvents,
    fetchUpcomingWeeklyEvents,
    registrationStatus,
    filters,
    fetchEvents,
    fetchEventsForMap,
}) => {
    const navigate = useNavigate();
    const divRef = useRef(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [displayError, setDisplayError] = useState<boolean>(false);
    const [errorTitle, setErrorTitle] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const headerRef = useRef(null);
    const whatIsNextRef = useRef(null);

    const dispatch = useDispatch();

    const { height: headerHeight } = useContainerDimensions(headerRef);

    const browser = useMemo(() => Bowser.getParser(window.navigator.userAgent).getBrowser(), []);

    const errorHandler = useCallback(() => {
        setLoading(false);
    }, []);

    const successHandler = useCallback(() => {
        setLoading(false);
        dispatch(eventsActions.updateRegistrationStatus(RegistrationStatus.AFTER_CONFIRMATION));
    }, [dispatch]);

    const { register } = useRegistrationOperations({
        authenticatedUser,
        clients: selectedEvent?.clients || queryParameters.clients,
        onError: errorHandler,
        onSuccess: successHandler,
    });

    const events = useSelector<RootState, EventModel[]>((state) => state.events.fetchedEvents);

    const clients = useSelector<RootState, ClientModel[]>((state) => state.clients.fetchedClients);

    const handleEventRegisterModal = useCallback(async () => {
        if (!authenticatedUser) {
            dispatch(eventsActions.updateRegistrationStatus(RegistrationStatus.AUTHENTICATING));
            return;
        }

        const clientFound = clients.find((client) => client.parameter === authenticatedUser.client);
        const planNamesInClient = clientFound?.nameOnEvents.map((name) => name.toLowerCase());

        if (clientFound && intersection(planNamesInClient, selectedEvent?.clients).length === 0) {
            let message = TranslationService.getInstance().getPhrase(
                'registration.modals.restrictions.not_in_health_plan.message'
            );

            message = mustache.render(message, {
                health_plan_user:
                    authenticatedUser.clientNames.length > 0
                        ? authenticatedUser.clientNames.join(', ')
                        : authenticatedUser.client,
            });

            setErrorTitle(
                TranslationService.getInstance().getPhrase(
                    'registration.modals.restrictions.not_in_health_plan.title'
                )
            );

            setErrorMessage(message);
            setDisplayError(true);
            return;
        }

        if (registrationStatus === RegistrationStatus.VIEWING_DETAILS) {
            dispatch(eventsActions.updateRegistrationStatus(RegistrationStatus.CONFIRMING));
            return;
        }

        if (registrationStatus === RegistrationStatus.CONFIRMING) {
            setLoading(true);

            await register(
                selectedEvent?.id as string,
                authenticatedUser.canItBeContactedThroughEmail,
                authenticatedUser.canItBeContactedThroughPhone,
                true,
                Boolean(selectedEvent?.isAuthenticatedUserRegistered)
            );

            dispatch(eventsActions.updateAllEventIds([]));
            fetchEventsForMap();

            if (selectedEvent?.isAuthenticatedUserRegistered) {
                dispatch(eventsActions.updateRegistrationStatus(RegistrationStatus.VIEWING_EVENTS));
                dispatch(eventsActions.selectEvent(undefined));
            }

            return;
        }

        if (registrationStatus === RegistrationStatus.AFTER_CONFIRMATION) {
            if (queryParameters.eventId) {
                navigate('/');
            }
            showBackAllEvents();
        }
    }, [
        authenticatedUser,
        registrationStatus,
        dispatch,
        register,
        selectedEvent?.id,
        selectedEvent?.isAuthenticatedUserRegistered,
        fetchEvents,
        filters,
        fetchEventsForMap,
        queryParameters.eventId,
        showBackAllEvents,
        navigate,
        clients,
    ]);

    const handleSelectionOfEvent = useCallback(
        (event: EventModel) => {
            selectEvent(event);
            // @ts-ignore
            divRef?.current.dialog.firstChild.children[0].scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        [selectEvent, divRef]
    );

    const [isShareModalDisplayed, displayShareModal] = useState(false);

    const sharingHandler = useCallback(() => {
        const eventParameter = selectedEvent?.id;
        const linkToShare = `${Config.SelfServeSharingUrl}?event=${eventParameter}`;

        navigator.clipboard.writeText(linkToShare);
        displayShareModal(true);
    }, [selectedEvent?.id]);

    const closingSharingModalHandler = useCallback(() => {
        displayShareModal(false);
    }, []);

    const closingErrorModalHandler = useCallback(() => {
        setDisplayError(false);
    }, []);

    const onClosingModal = useCallback(() => {
        if (registrationStatus === RegistrationStatus.CONFIRMING) {
            dispatch(eventsActions.updateRegistrationStatus(RegistrationStatus.VIEWING_DETAILS));
            return;
        }

        if (queryParameters.eventId) {
            navigate('/');
        }
        showBackAllEvents();
    }, [navigate, queryParameters.eventId, showBackAllEvents, registrationStatus]);

    const onLeftButtonClick = useCallback(() => {
        if (registrationStatus === RegistrationStatus.AFTER_CONFIRMATION) {
            dispatch(eventsActions.updateOnlyRegisteredEventsFilter(true));
        }

        showBackAllEvents();
    }, [showBackAllEvents, registrationStatus, dispatch]);

    useEffect(() => {
        if (selectedEvent) {
            fetchUpcomingWeeklyEvents({
                eventId: selectedEvent.id,
                clients: selectedEvent.clients,
            });
        }
    }, [fetchUpcomingWeeklyEvents, selectedEvent]);

    return (
        <>
            {isShareModalDisplayed && (
                <ShareModal
                    isOpen={isShareModalDisplayed}
                    onClose={closingSharingModalHandler}
                    message={'The link of this event is now copied to your clipboard'}
                    title={'Event link copied!'}
                />
            )}

            {displayError && (
                <ErrorModal
                    isOpen={displayError}
                    onClose={closingErrorModalHandler}
                    message={errorMessage}
                    title={errorTitle}
                    otherEvents={events.filter(
                        (event) =>
                            event.id !== selectedEvent?.id &&
                            event.clients.includes(authenticatedUser?.client as string)
                    )}
                />
            )}

            {registrationStatus === RegistrationStatus.WHAT_IS_NEXT && selectedEvent && (
                <EventRegistrationCompleteModal
                    event={selectedEvent}
                    authenticatedUser={authenticatedUser}
                    showBackAllEvents={onClosingModal}
                    registrationComplete={true}
                    clients={queryParameters.clients}
                    events={fetchedUpcomingWeeklyEvents}
                    selectedEventIds={fetchedUpcomingWeeklyEvents.map((event) => event.id)}
                    isLoading={isLoadingEvents}
                    onViewDetails={() => {}}
                />
            )}

            <div
                className={`${Styles.EventDetailsModal} d-flex justify-content-end`}
                style={browser.name === 'Safari' ? { height: '90vh' } : undefined}
            >
                <div className={Styles.Modal}>
                    <div className={Styles.Header} style={{ height: headerHeight }}>
                        <EventDetailsHeader
                            onClosingModal={onClosingModal}
                            event={selectedEvent}
                            headerRef={headerRef}
                        />
                    </div>

                    <div
                        className={Styles.Body}
                        style={{ height: `calc(100vh - calc(95px + ${headerHeight}px))` }}
                    >
                        {registrationStatus === RegistrationStatus.VIEWING_DETAILS && (
                            <ViewDetailsStatus handleSelectionOfEvent={handleSelectionOfEvent} />
                        )}

                        {registrationStatus === RegistrationStatus.CONFIRMING && (
                            <ConfirmingStatus onClosingModal={onClosingModal} />
                        )}

                        {registrationStatus === RegistrationStatus.AFTER_CONFIRMATION && (
                            <AfterConfirmationStatus
                                handleSelectionOfEvent={handleSelectionOfEvent}
                                containerRef={whatIsNextRef}
                                event={selectedEvent as EventModel}
                            />
                        )}
                    </div>

                    <div className={Styles.Footer}>
                        <EventDetailsActions
                            event={selectedEvent}
                            loading={loading}
                            onShareEventClick={sharingHandler}
                            onLeftButtonClick={onLeftButtonClick}
                            onRightButtonClick={handleEventRegisterModal}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
