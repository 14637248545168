import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { EventModel } from '@/events/models';
import { Button, ButtonType } from '@/components/form-controls/button';
import Styles from './Styles.module.scss';
import { IMAGES } from '@/assets/images';
import { DateModel } from '@/utilities/dates';
import { TranslationService } from '@/services/translation';
import { getRegistrationButtonLabel } from '@/events/pages/all-events/utils';
import { VenueType } from '@/venues/models';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { AuthenticatedUserModel } from '@/authentication/models';
import { useRegistrationFromEventsList } from '@/events/hooks/use-registration-from-events-list';
import GeneralStyles from '@/events/components/events-list-items/general-styles.module.scss';
import { EventBadges } from '@/events/components/event-badges';

type Props = {
    events: EventModel[];
    onClose: () => void;
    onDisplayDetails: (event: EventModel) => void;
};

export const EventsListModal: FunctionComponent<Props> = ({
    events,
    onClose,
    onDisplayDetails,
}) => {
    const authenticatedUser = useSelector<RootState, AuthenticatedUserModel | undefined>(
        (state) => state.authentication.authenticatedUser
    );

    const [eventSelected, setEventSelected] = useState<EventModel>(events[0]);

    const { onAddMeToGuestList, loading } = useRegistrationFromEventsList({ event: eventSelected });

    const numberOfEvents = useMemo(() => events.length, [events]);

    const numberOfEventsLabel = useMemo(() => {
        if (numberOfEvents === 1) {
            return TranslationService.getInstance().getPhrase('map.modals.title.singular');
        }

        const label = TranslationService.getInstance().getPhrase('map.modals.title.plural');
        return label.replace('_', numberOfEvents.toString());
    }, [numberOfEvents]);

    const closeHandler = useCallback(() => {
        onClose();
    }, [onClose]);

    const eventClickHandler = useCallback(
        (event: EventModel) => {
            setEventSelected(event);
        },
        [setEventSelected]
    );

    const displayDetailsHandler = useCallback(() => {
        onDisplayDetails(eventSelected);
    }, [eventSelected, onDisplayDetails]);

    return (
        <Modal
            centered={true}
            show={true}
            size="lg"
            className={Styles.EventsListModal}
            contentClassName={Styles.ModalContent}
        >
            <Modal.Body className={'p-0'}>
                <>
                    <Row className={`mx-0 py-3 ${Styles.Header}`}>
                        <Col className={'d-flex justify-content-between align-items-center'}>
                            <span className={`${Styles.Title}`}>{numberOfEventsLabel}</span>

                            <Button
                                type={ButtonType.PRIMARY_BLUE_1}
                                onClick={closeHandler}
                                customClassName={`px-4 py-2 ${Styles.CloseButton}`}
                            >
                                {TranslationService.getInstance().getPhrase(
                                    'map.modals.buttons.close'
                                )}
                            </Button>
                        </Col>
                    </Row>

                    <Row className={`mx-0 ${Styles.Body}`}>
                        <Col className={`p-3 ${Styles.SelectableTitles}`} xs={4}>
                            {events.map((event: EventModel) => (
                                <Row
                                    key={event.id}
                                    className={`mx-0 ${Styles.Title} ${
                                        eventSelected.id === event.id && Styles.active
                                    }`}
                                    onClick={() => eventClickHandler(event)}
                                >
                                    <Col className={'py-2'}>{event.name}</Col>
                                </Row>
                            ))}
                        </Col>

                        <Col className={`p-3 ${Styles.SelectedEventInformation}`} xs={8}>
                            <Row className={'pb-3 mx-0'}>
                                <Col className={'d-flex align-items-center px-1'}>
                                    <span className={Styles.Title}>{eventSelected.name}</span>
                                </Col>

                                <Col
                                    className={
                                        'd-flex justify-content-end flex-xl-row flex-lg-row flex-md-column flex-sm-column px-1'
                                    }
                                >
                                    <EventBadges event={eventSelected} />
                                </Col>
                            </Row>

                            {eventSelected.description && (
                                <Row className={'pb-3 mx-0'}>
                                    <Col className={'px-1'}>
                                        <span className={Styles.Description}>
                                            {eventSelected.description}
                                        </span>
                                    </Col>
                                </Row>
                            )}

                            {authenticatedUser && (
                                <Row className={'pb-1 mx-0'}>
                                    <Col className={'px-1'}>
                                        {eventSelected.venue.type === VenueType.IN_PERSON ? (
                                            <img
                                                alt="direction-sign-icon"
                                                className={`pe-2 ${Styles.DirectionSignIcon}`}
                                                src={IMAGES.DirectionSignIcon}
                                            />
                                        ) : (
                                            <img
                                                alt="headset-icon"
                                                className={`pe-2 ${Styles.Icon}`}
                                                src={IMAGES.HeadsetIcon}
                                            />
                                        )}

                                        <span className={Styles.Location}>
                                            {eventSelected.venue.name}
                                        </span>
                                    </Col>
                                </Row>
                            )}

                            {!authenticatedUser && (
                                <Row className={'pt-2 pb-1 mx-0'}>
                                    <Col className={'px-1 d-flex align-items-center'}>
                                        <div className={`${Styles.SignInBadge} px-2`}>
                                            {TranslationService.getInstance().getPhrase(
                                                'events_list.badges.sign_in'
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            )}

                            <Row className={'pb-1 mx-0'}>
                                <Col className={'px-1'}>
                                    <img
                                        alt="map-location-icon"
                                        className={`pe-2 ${Styles.Icon}`}
                                        src={IMAGES.MapLocationIcon}
                                    />
                                    <span
                                        className={Styles.Location}
                                    >{`${eventSelected.venue.city} ${eventSelected.venue.state} ${eventSelected.venue.zipcode}`}</span>
                                </Col>
                            </Row>

                            {authenticatedUser && (
                                <Row className={'pb-2 mx-0'}>
                                    <Col className={'ps-1 pe-4'} xs={'auto'}>
                                        <img
                                            alt="calendar-icon"
                                            className={`pe-2 ${Styles.CalendarIcon}`}
                                            src={IMAGES.CalendarIcon}
                                        />
                                        <span className={Styles.Details}>{`${new DateModel(
                                            eventSelected.startDateInMilliseconds
                                        ).format('MMMM DD')} at ${new DateModel(
                                            eventSelected.startDateInMilliseconds
                                        ).format('LT')} to ${new DateModel(
                                            eventSelected.endDateInMilliseconds
                                        ).format(
                                            'LT'
                                        )} (${new DateModel(eventSelected.endDateInMilliseconds).format('z')})`}</span>
                                    </Col>
                                </Row>
                            )}

                            <Row className={'pb-1 pt-2 mx-0'}>
                                <Col
                                    className={
                                        'd-flex flex-xl-row flex-lg-row flex-md-column flex-sm-column justify-content-end px-1'
                                    }
                                >
                                    <Button
                                        type={ButtonType.LIGHT_GRAY_BLUE}
                                        onClick={displayDetailsHandler}
                                        customClassName={`px-4 py-2 ${GeneralStyles.RegisterButton}`}
                                    >
                                        {getRegistrationButtonLabel(eventSelected)}
                                    </Button>

                                    {authenticatedUser &&
                                        !eventSelected.isAuthenticatedUserRegistered && (
                                            <>
                                                <span className={'px-1 py-1'} />

                                                <Button
                                                    type={ButtonType.LIGHT_BLUE}
                                                    onClick={onAddMeToGuestList}
                                                    customClassName={`px-4 py-2 ${GeneralStyles.RegisterButton}`}
                                                    disabled={loading}
                                                >
                                                    <div
                                                        className={`${GeneralStyles.RegisterButtonContent} d-flex align-items-center`}
                                                    >
                                                        <span className={'ps-2'}>
                                                            Add me to guest list
                                                        </span>
                                                    </div>
                                                </Button>
                                            </>
                                        )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            </Modal.Body>
        </Modal>
    );
};
