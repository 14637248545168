import React, { FunctionComponent, useCallback } from 'react';

import Styles from './Styles.module.scss';
import { EventModel } from '@/events/models';
import { EventDetailsImage } from '@/events/components/event-details-image';
import { VenueType } from '@/venues/models';
import { MapView } from '@/events/components/map-view';
import { EventLocation } from '@/events/components/event-location';
import { EventTime } from '@/events/components/event-time';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { AuthenticatedUserModel } from '@/authentication/models';
import { EventDetailsMiscellaneous } from '@/events/components/event-details-miscellaneous';
import { OtherEvents } from '@/events/components/other-events';
import { IMAGES } from '@/assets/images';
import { TranslationService } from '@/services/translation';
import { eventsActions } from '@/events/reducer';
import { RegistrationStatus } from '@/events/reducer/types';

type Props = {
    handleSelectionOfEvent: (event: EventModel) => void;
};

const SIGN_IN_DELIMITER = '|';

export const ViewDetailsStatus: FunctionComponent<Props> = ({ handleSelectionOfEvent }) => {
    const dispatch = useDispatch();

    const selectedEvent = useSelector<RootState, EventModel | undefined>(
        (state) => state.events.selectedEvent
    );

    const authenticatedUser = useSelector<RootState, AuthenticatedUserModel | undefined>(
        (state) => state.authentication.authenticatedUser
    );

    const getSecondLineOfSignInMessage = useCallback(() => {
        const messageParts = TranslationService.getInstance()
            .getPhrase('registration.map.sign_in.second_line')
            .split(SIGN_IN_DELIMITER);

        return (
            <>
                {messageParts[0]}
                <a
                    onClick={() => {
                        dispatch(
                            eventsActions.updateRegistrationStatus(
                                RegistrationStatus.AUTHENTICATING
                            )
                        );
                    }}
                >
                    {messageParts[1]}
                </a>
                {messageParts[2]}
            </>
        );
    }, []);

    return (
        <div className={Styles.ViewDetailsStatus}>
            <div className="p-0 pt-4 mb-3 mt-2">
                {selectedEvent?.description && (
                    <span className={Styles.EventDescription}>{selectedEvent?.description}</span>
                )}
            </div>

            {selectedEvent?.imageUrl && <EventDetailsImage event={selectedEvent} />}

            <div className={`p-0 d-block mt-3`}>
                {selectedEvent?.venue.type === VenueType.IN_PERSON ? (
                    <div className={`w-100 mb-3 ${Styles.EventMapContainer}`}>
                        {selectedEvent && authenticatedUser && (
                            <MapView
                                events={[selectedEvent]}
                                authenticatedUser={authenticatedUser}
                                onViewDetails={() => {}}
                                onSelectedEvents={() => {}}
                                areEventsClickable={false}
                                customClassname={Styles.CustomInnerMapClass}
                                loadingEvents={false}
                                recordBoundaries={false}
                                showFirstEventLocationOnly
                            />
                        )}

                        {selectedEvent && !authenticatedUser && (
                            <div
                                className={`${Styles.SignInBlock} d-flex flex-column align-items-center justify-content-center`}
                            >
                                <img alt="cannot-view-map-until-sign-in" src={IMAGES.LockIcon} />

                                <span>
                                    {TranslationService.getInstance().getPhrase(
                                        'registration.map.sign_in.first_line'
                                    )}
                                </span>

                                <span>{getSecondLineOfSignInMessage()}</span>
                            </div>
                        )}
                    </div>
                ) : null}

                <div className={'d-xl-block d-lg-block d-md-block d-sm-none d-none w-100 py-2'} />

                <EventLocation
                    event={selectedEvent as EventModel}
                    isAuthenticated={!!authenticatedUser}
                />

                {authenticatedUser && <EventTime event={selectedEvent as EventModel} />}

                <div
                    className={'d-xl-block d-lg-block d-md-block d-sm-none d-none w-100 pb-4 pt-2'}
                >
                    <div className={Styles.DividerFull} />
                </div>
            </div>

            <EventDetailsMiscellaneous event={selectedEvent as EventModel} />

            <div className={`${Styles.DividerFull} my-4`} />

            <OtherEvents handleSelectionOfEvent={handleSelectionOfEvent} />
        </div>
    );
};
