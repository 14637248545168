import React, { FunctionComponent } from 'react';
import Col from 'react-bootstrap/Col';
import { Dropdown } from '@/components/form-controls/dropdown';
import Row from 'react-bootstrap/Row';
import { EventsFiltering } from '@/events/components/events-filtering/use-events-filtering-operations';
import { TranslationService } from '@/services/translation';
import { RadioButtonsGroup } from '@/components/form-controls/radiobuttons-group';
import { useSelector } from 'react-redux';
import { EventFilters } from '@/events/reducer/types';
import { RootState } from '@/store';
import Styles from './Styles.module.scss';
import { ViewModeWebSwitch } from '@/events/components/view-mode';
import { FiltersButton } from '@/events/components/events-filtering/components/filters-button';
import { ZipcodeBox } from '@/events/components/events-filtering/components/zipcode-box';
import { SearchBox } from '@/events/components/events-filtering/components/search-box';
import { AuthenticatedUserModel } from '@/authentication/models';

type Props = {
    eventsFilteringOperations: EventsFiltering;
    onFilterButtonClick: () => void;
};

export const WebFiltering: FunctionComponent<Props> = ({
    eventsFilteringOperations,
    onFilterButtonClick,
}) => {
    const filters = useSelector<RootState, EventFilters>((state) => state.events.filters);

    const authenticatedUser = useSelector<RootState, AuthenticatedUserModel | undefined>(
        (state) => state.authentication.authenticatedUser
    );

    const hasZipcodeBeingInput = useSelector<RootState, boolean>(
        (state) => state.events.hasZipcodeBeingInput
    );

    return (
        <Row className={`${Styles.WebFiltering} py-1 px-3 h-100`}>
            <Col className={'px-0 h-100 d-flex justify-content-between align-items-center'}>
                <div className={`${Styles.Search} align-items-center pe-1`}>
                    <SearchBox
                        label={TranslationService.getInstance().getPhrase(
                            'filters.bar.search.label'
                        )}
                        placeholder={TranslationService.getInstance().getPhrase(
                            'filters.bar.search.placeholder'
                        )}
                        type="text"
                        value={eventsFilteringOperations.values.searchText}
                        onChange={eventsFilteringOperations.handlers.searchTextChangeHandler}
                        onEnter={eventsFilteringOperations.handlers.searchTextEnterKeyPressHandler}
                        onRightIconClick={eventsFilteringOperations.handlers.searchTextClickHandler}
                        isValid={true}
                        showRightIcon={
                            eventsFilteringOperations.values.searchText !== undefined &&
                            eventsFilteringOperations.values.searchText.length > 2
                        }
                    />
                </div>

                <div className={`${Styles.State} align-items-center px-1`}>
                    <Dropdown
                        label={TranslationService.getInstance().getPhrase(
                            'filters.bar.state.label'
                        )}
                        preselectedOptions={filters.states}
                        options={eventsFilteringOperations.options.states}
                        onChange={eventsFilteringOperations.handlers.statesChangeHandler}
                        firstOptionRepresentsAllOptions={true}
                    />
                </div>

                <div className={`${Styles.Zipcode} align-items-center px-1`}>
                    <ZipcodeBox
                        label={TranslationService.getInstance().getPhrase(
                            'filters.bar.zipcode.label'
                        )}
                        placeholder={TranslationService.getInstance().getPhrase(
                            'filters.bar.zipcode.placeholder'
                        )}
                        type="text"
                        value={filters.zipcode}
                        onChange={eventsFilteringOperations.handlers.zipcodeChangeHandler}
                        isValid={true}
                        showTooltipInitially={true}
                    />
                </div>

                <div className={`${Styles.EventType} align-items-center px-1`}>
                    <Dropdown
                        label={TranslationService.getInstance().getPhrase(
                            'filters.bar.event_type.label'
                        )}
                        preselectedOptions={filters.venueTypes}
                        options={eventsFilteringOperations.options.eventTypes}
                        onChange={eventsFilteringOperations.handlers.eventTypesChangeHandler}
                        firstOptionRepresentsAllOptions={true}
                        disabled={!hasZipcodeBeingInput}
                        disableMessage={
                            !hasZipcodeBeingInput
                                ? TranslationService.getInstance().getPhrase(
                                      'filters.bar.event_type.disable_message'
                                  )
                                : undefined
                        }
                    />
                </div>

                <div className={`${Styles.HealthPlan} align-items-center px-1`}>
                    <RadioButtonsGroup
                        id="client-filter"
                        label={TranslationService.getInstance().getPhrase(
                            'filters.bar.client.label'
                        )}
                        preselectedOption={filters.client || ''}
                        options={eventsFilteringOperations.options.clients}
                        onChange={eventsFilteringOperations.handlers.clientChangeHandler}
                        firstOptionRepresentsAllOptions={!authenticatedUser}
                        isDisabled={!!authenticatedUser}
                    />
                </div>

                <div className={`${Styles.Language} align-items-center px-1`}>
                    <Dropdown
                        label={TranslationService.getInstance().getPhrase(
                            'filters.bar.language.label'
                        )}
                        preselectedOptions={filters.languages}
                        options={eventsFilteringOperations.options.languages}
                        onChange={eventsFilteringOperations.handlers.languagesChangeHandler}
                        firstOptionRepresentsAllOptions={true}
                    />
                </div>

                <div className={`${Styles.FiltersButton} flex-column px-1`}>
                    <span className={'mb-1'}>More filters</span>

                    <FiltersButton onFilterButtonClick={onFilterButtonClick} />
                </div>

                <div className={`${Styles.ViewMode} align-items-center ps-1`}>
                    <ViewModeWebSwitch />
                </div>
            </Col>
        </Row>
    );
};
