import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { EventModel } from '@/events/models';
import Styles from './Styles.module.scss';
import { AuthenticatedUserModel } from '@/authentication/models';
import { EventsListItemLoading } from '@/events/components/events-list-items/event-list-small-item/item-loading';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { EventFilters, ViewMode } from '@/events/reducer/types';
import { eventsActions } from '@/events/reducer';
import { TranslationService } from '@/services/translation';
import { MapAppModal } from '@/events/components/events-list-items/map-app-modal';
import { VenueType } from '@/venues/models';
import { SignInPanel } from '@/events/components/sign-in-panel';
import { EventsGroupList } from '@/events/components/events-list/events-group-list';
import { useEventsGrouped } from '@/events/components/events-list/use-events-grouped';
import { NationwideEventsList } from '@/events/components/nationwide-events-list';

type Props = {
    authenticatedUser: AuthenticatedUserModel | undefined;
    clients: string[];
    selectedEventIds: string[];
    isLoading: boolean;
    onViewDetails: (event: EventModel) => void;
    registeredEventsOnly: boolean;
    isMapHidden?: boolean;
    containerId?: string;
};

export const EventsList: FunctionComponent<Props> = ({
    authenticatedUser,
    selectedEventIds,
    isLoading,
    onViewDetails,
    isMapHidden = false,
    containerId,
}) => {
    const [selectedMapAppEvent, setSelectedMapAppEvent] = useState<EventModel>();

    const dispatch = useDispatch();
    const filters = useSelector<RootState, EventFilters>((state) => state.events.filters);

    const numberOfAvailableEvents = useSelector<RootState, number>(
        (state) => state.events.numberOfAvailableEvents
    );

    const numberOfRegisteredEvents = useSelector<RootState, number>(
        (state) => state.events.numberOfRegisteredEvents
    );

    const numberOfShownNonNationwideEvents = useSelector<RootState, number>(
        (state) => state.events.numberOfShownNonNationwideEvents
    );

    const totalNumberOfNonNationwideEvents = useSelector<RootState, number>(
        (state) => state.events.totalNumberOfNonNationwideEvents
    );

    const viewMode = useSelector<RootState, ViewMode>((state) => state.events.viewMode);

    const eventsGroups = useEventsGrouped();

    const hasMoreNonNationwideEvents = useMemo(() => {
        return isLoading || numberOfShownNonNationwideEvents < totalNumberOfNonNationwideEvents;
    }, [numberOfShownNonNationwideEvents, isLoading, totalNumberOfNonNationwideEvents]);

    const next = useCallback(() => {
        if (numberOfShownNonNationwideEvents !== totalNumberOfNonNationwideEvents && !isLoading) {
            dispatch(eventsActions.goToNextPage());
        }
    }, [numberOfShownNonNationwideEvents, totalNumberOfNonNationwideEvents, isLoading]);

    const showAvailableEvents = useCallback(() => {
        dispatch(eventsActions.updateOnlyRegisteredEventsFilter(false));
    }, [dispatch]);

    const showRegisteredEvents = useCallback(() => {
        dispatch(eventsActions.updateOnlyRegisteredEventsFilter(true));
    }, [dispatch]);

    const showLoading = useCallback(
        () => (
            <Row className={'mx-0'}>
                <Col xs={12} className={'pb-4 px-3'}>
                    <EventsListItemLoading />
                </Col>
                <Col xs={12} className={'pb-4 px-3'}>
                    <EventsListItemLoading />
                </Col>
                <Col xs={12} className={'pb-4 px-3'}>
                    <EventsListItemLoading />
                </Col>
            </Row>
        ),
        []
    );

    const onShowMapAppModal = useCallback(
        (event: EventModel) => {
            if (event.venue.type === VenueType.IN_PERSON) {
                setSelectedMapAppEvent(event);
            }
        },
        [setSelectedMapAppEvent]
    );

    const onHideMapAppModal = useCallback(() => {
        setSelectedMapAppEvent(undefined);
    }, [setSelectedMapAppEvent]);

    return (
        <div
            className={`${Styles.Container} pt-2`}
            style={{
                maxWidth: viewMode === ViewMode.EVENTS_LIST ? 1303 : 651,
            }}
        >
            {!authenticatedUser && (
                <div className={'d-xl-block d-lg-block d-md-none d-sm-none d-none'}>
                    <SignInPanel />
                </div>
            )}

            <Row className={'mx-0 pb-4 w-100'}>
                <Col
                    className={
                        'px-3 d-flex flex-xl-row flex-lg-row flex-md-row flex-sm-row flex-column'
                    }
                >
                    <div
                        onClick={showAvailableEvents}
                        className={`${
                            filters.onlyRegisteredEvents
                                ? Styles.BubbleInactive
                                : Styles.BubbleActive
                        } px-3 py-1`}
                    >
                        <span>{`${TranslationService.getInstance().getPhrase(
                            'events_list.results.options.show_available_events.label'
                        )} (${numberOfAvailableEvents})`}</span>
                    </div>

                    {authenticatedUser && (
                        <>
                            <span className={'px-1 py-1'}></span>

                            <div
                                onClick={showRegisteredEvents}
                                className={`${
                                    !filters.onlyRegisteredEvents
                                        ? Styles.BubbleInactive
                                        : Styles.BubbleActive
                                } px-3 py-1`}
                            >
                                <span>
                                    {`${TranslationService.getInstance().getPhrase(
                                        'events_list.results.options.show_registered_events.label'
                                    )} (${numberOfRegisteredEvents})`}
                                </span>
                            </div>
                        </>
                    )}
                </Col>
            </Row>

            <NationwideEventsList
                selectedEventIds={selectedEventIds}
                isMapHidden={isMapHidden}
                onViewDetails={onViewDetails}
                onShowMapAppModal={onShowMapAppModal}
            />

            <InfiniteScroll
                next={next}
                hasMore={hasMoreNonNationwideEvents}
                loader={showLoading()}
                dataLength={numberOfShownNonNationwideEvents}
                scrollableTarget={containerId}
            >
                {eventsGroups
                    .filter((item) => item.events.length > 0)
                    .map((item, index) => (
                        <>
                            <EventsGroupList
                                key={
                                    item.events.length > 0
                                        ? item.events[0].id
                                        : `In_Person_Events_Group_${index + 1}`
                                }
                                isMapHidden={isMapHidden}
                                onViewDetails={onViewDetails}
                                selectedEventIds={selectedEventIds}
                                onShowMapAppModal={onShowMapAppModal}
                                eventsGroup={item}
                            />

                            <div className={'pb-4'}></div>
                        </>
                    ))}
            </InfiniteScroll>

            <Row className="mx-0 pb-3 w-100">
                <Col className="px-3 d-flex w-100">
                    <div className={`${Styles.MessageBox} w-100 py-3 px-4`}>
                        <span>This is the final event nearby.</span>

                        <p className={'mb-0 pt-1'}>
                            Explore more events by zooming out or trying different zip codes.
                        </p>
                    </div>
                </Col>
            </Row>

            <MapAppModal
                event={selectedMapAppEvent}
                show={selectedMapAppEvent !== undefined}
                onClose={onHideMapAppModal}
            />
        </div>
    );
};
