import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import Modal from 'react-bootstrap/Modal';
// eslint-disable-next-line import/named
import { v4 as generateUUIDV4 } from 'uuid';
import { useRegistrationModalData } from '@/contacts/components/registration-modal/use-registration-modal-data';
import { StepOne } from '@/contacts/components/registration-modal/components/step-one';
import { StepTwo } from '@/contacts/components/registration-modal/components/step-two';
import { searchMembers } from '@/contacts/api';
import { StepThree } from '@/contacts/components/registration-modal/components/step-three';
import { MemberSearchResultModel } from '@/contacts/models';
import { StepOneLoading } from '@/contacts/components/registration-modal/components/step-one-loading';
import { StepFour } from '@/contacts/components/registration-modal/components/step-four';
import { useRegistrationFormData } from '@/events/pages/event-registration/components/use-registration-form-data';
import { ContactMatchingBody, MatchedContact } from '@/events/api/types';
import { matchContact } from '@/events/api';
import { StepFive } from '@/contacts/components/registration-modal/components/step-five';
import Styles from './Styles.module.scss';
import { getNewToken, isTokenValid } from '@/authentication/api';
import { RegistrationModalConnectorProps } from '@/contacts/components/registration-modal/connector';
import ShieldIcon from '@/assets/icons/ShieldIcon';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from '@/assets/images/logo.png';
import { useContainerDimensions } from '@/hooks/use-container-dimensions';
import { Button, ButtonType } from '@/components/form-controls/button';
import { formatPhone } from '@/utilities/phone-util';
import {
    formSubmitted,
    formSubmittedAfterUnmatched,
} from '@/contacts/components/registration-modal/helpers';
import { StepBoard } from '@/contacts/components/registration-modal/components/step-board';
import { StepActions } from '@/contacts/components/registration-modal/components/step-actions';
import { TranslationService } from '@/services/translation';
import { Carousel } from '@/contacts/components/registration-modal/components/Carousel';
import { TOKEN_PROP_NAME } from '@/constants/local-storage';

type Props = RegistrationModalConnectorProps & {
    onAuthenticated: () => void;
};

export const RegistrationModalComponent: FunctionComponent<Props> = ({
    queryParameters,
    storeAuthenticatedUser,
    selectedContact,
    selectedEvent,
    searchResults,
    storeSearchResults,
    selectContact,
    storeMatchedContact,
    onAuthenticated,
    signInForm,
    setSignInForm,
    setClientNameFromAuthenticatedUser,
}) => {
    const [step, setStep] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [wasContactAlreadyMatched, setContactAlreadyMatched] = useState<boolean>(false);
    const registrationModalData = useRegistrationModalData();
    const registrationFormData = useRegistrationFormData({});
    const StepZeroRef = useRef<HTMLInputElement>();
    const { width } = useContainerDimensions(StepZeroRef);
    const [stepZero, setStepZero] = useState(false);

    useLayoutEffect(() => {
        if (width < 575 && step === 1) {
            setStepZero(true);
        } else {
            setStepZero(false);
        }
    }, [width]);

    const getClientNamesForSubmission = useCallback(
        () =>
            queryParameters.clients.length > 0
                ? queryParameters.clients.join(';')
                : selectedContact?.clientParameter,
        [queryParameters, selectedContact]
    );

    const moveToStepTwoOrThree = useCallback(
        async ({ phoneNumber, lastName }: { phoneNumber?: string; lastName?: string }) => {
            const finalPhoneNumber = (registrationModalData.phoneNumber.value ||
                phoneNumber) as string;
            const finalLastName = (registrationModalData.lastName.value || lastName) as string;

            setLoading(true);

            try {
                const membersFound = await searchMembers(finalLastName, finalPhoneNumber);
                storeSearchResults(membersFound);

                if (membersFound.length === 1) {
                    selectContact(membersFound[0]);
                    setStep(2);
                } else if (membersFound.length > 1) {
                    setStep(2);
                } else {
                    registrationFormData.mobilePhone.setValue(finalPhoneNumber);

                    registrationFormData.homePhone.setValue(finalPhoneNumber);

                    registrationFormData.lastName.setValue(finalLastName);
                    setStep(4);
                }
            } catch (error) {
                console.log('ERROR', error);
            }

            setLoading(false);
        },
        [
            registrationModalData.phoneNumber.value,
            registrationModalData.lastName.value,
            storeSearchResults,
            selectContact,
            registrationFormData.mobilePhone.setValue,
            registrationFormData.homePhone.setValue,
            registrationFormData.lastName.setValue,
        ]
    );

    const findMatch = useCallback(async (): Promise<void> => {
        setLoading(true);

        const body: ContactMatchingBody = {
            first_name: registrationFormData.firstName.value,
            last_name: registrationFormData.lastName.value,
            mobile_phone: formatPhone(registrationFormData.mobilePhone.value),
            home_phone: formatPhone(registrationFormData.homePhone.value),
            zip_code: registrationFormData.zipCode.value,
            client_name: getClientNamesForSubmission(),
            event_id: selectedEvent?.id,
            primary_email: registrationFormData.email.value,
            keep_updated_email: false,
            keep_updated_text: false,
            personal_information_sharing_allowed: false,
        };

        try {
            const matchedContact: MatchedContact | undefined = await matchContact(body);

            if (matchedContact) {
                const newSearchResults = [
                    {
                        firstName: registrationFormData.firstName.value as string,
                        lastName: registrationFormData.lastName.value as string,
                        id: matchedContact.contact_id,
                        client: matchedContact.client,
                        zipCode: registrationFormData.zipCode.value,
                    },
                ];

                storeSearchResults(newSearchResults);
                selectContact(newSearchResults[0]);
                setContactAlreadyMatched(true);
                setStep(3);
                formSubmittedAfterUnmatched(matchedContact.contact_id);
            } else {
                setStep(5);
                formSubmittedAfterUnmatched(undefined);
            }
        } catch (error) {
            setStep(5);
        }

        setLoading(false);
    }, [
        registrationFormData.firstName.value,
        registrationFormData.lastName.value,
        registrationFormData.mobilePhone.value,
        registrationFormData.homePhone.value,
        registrationFormData.zipCode.value,
        registrationFormData.email.value,
        getClientNamesForSubmission,
        selectedEvent?.id,
        storeSearchResults,
        selectContact,
    ]);

    const authenticate = useCallback(
        async (contactId: string): Promise<void> => {
            const token = await getNewToken(contactId, generateUUIDV4());
            localStorage.setItem(TOKEN_PROP_NAME, token);
            const authenticatedUser = await isTokenValid();
            storeAuthenticatedUser(authenticatedUser);
        },
        [storeAuthenticatedUser]
    );

    const moveToStepFour = useCallback(async () => {
        setLoading(true);

        if (!wasContactAlreadyMatched) {
            const body: ContactMatchingBody = {
                first_name: selectedContact?.firstName,
                last_name: selectedContact?.lastName,
                mobile_phone: formatPhone(registrationModalData.phoneNumber.value),
                home_phone: formatPhone(registrationModalData.phoneNumber.value),
                client_name: getClientNamesForSubmission(),
                event_id: selectedEvent?.id,
                zip_code: selectedContact?.zipCode,
                primary_email: undefined,
                keep_updated_email: false,
                keep_updated_text: false,
                personal_information_sharing_allowed: false,
            };

            const matchedContact: MatchedContact | undefined = await matchContact(body);

            if (matchedContact) {
                formSubmitted(matchedContact?.contact_id);
            }
        }

        storeMatchedContact({
            firstName: selectedContact?.firstName,
            lastName: selectedContact?.lastName,
            mobilePhone: formatPhone(
                registrationFormData.mobilePhone.value || registrationModalData.phoneNumber.value
            ),
            homePhone: formatPhone(
                registrationFormData.homePhone.value || registrationModalData.phoneNumber.value
            ),
            email: registrationFormData.email.value,
            zipCode: selectedContact?.zipCode,
        });

        await authenticate(selectedContact?.id as string);
        setClientNameFromAuthenticatedUser(selectedContact?.clientParameter as string);
        setLoading(false);
        onAuthenticated();
    }, [
        wasContactAlreadyMatched,
        storeMatchedContact,
        selectedContact?.firstName,
        selectedContact?.lastName,
        selectedContact?.zipCode,
        selectedContact?.id,
        registrationFormData.mobilePhone.value,
        registrationFormData.homePhone.value,
        registrationFormData.email.value,
        registrationModalData.phoneNumber.value,
        authenticate,
        onAuthenticated,
        getClientNamesForSubmission,
        selectedEvent?.id,
    ]);

    const isNextButtonDisabled = useMemo(() => {
        if (step === 1) {
            return (
                !registrationModalData.phoneNumber.isValid ||
                !registrationModalData.lastName.isValid
            );
        } else if (step === 2) {
            return selectedContact === undefined;
        } else if (step === 4) {
            return (
                !registrationFormData.isWholeFormValid.value || !registrationFormData.email.value
            );
        }
        return false;
    }, [
        step,
        registrationModalData.phoneNumber.isValid,
        registrationModalData.lastName.isValid,
        selectedContact,
        registrationFormData.isWholeFormValid,
    ]);

    const next = useCallback(() => {
        if (step === 1) {
            moveToStepTwoOrThree({});
        } else if (step === 2) {
            setStep(3);
        } else if (step === 3) {
            moveToStepFour();
        } else if (step === 4) {
            findMatch();
        }
    }, [step, moveToStepTwoOrThree, findMatch, moveToStepFour]);

    const notInContact = () => {
        setStep(4);
        registrationFormData.mobilePhone.setValue(
            registrationModalData.phoneNumber.value as string
        );

        registrationFormData.homePhone.setValue(registrationModalData.phoneNumber.value as string);

        registrationFormData.lastName.setValue(registrationModalData.lastName.value as string);
    };

    const previous = useCallback(() => {
        if (step === 3 && searchResults.length === 1) {
            setStep(1);
        } else if (step === 4) {
            setStep(1);
        } else {
            setStep(step - 1);
        }
    }, [step, searchResults]);

    const modalDialogClassName = useMemo(() => {
        if ([2, 3].includes(step)) {
            return Styles.RegistrationModalDialogStep2;
        }

        if (step === 4) {
            return Styles.RegistrationModalDialogStep4;
        }

        return Styles.RegistrationModalDialog;
    }, [step]);

    const modalContentClassName = useMemo(() => {
        if (step === 1) {
            return Styles.RegistrationModalContainer;
        }

        if (step === 4) {
            return '';
        }

        return Styles.MobileFullScreenHeight;
    }, [step]);

    useEffect(() => {
        if (signInForm.phoneNumber && signInForm.lastName) {
            registrationModalData.lastName.setValue(signInForm.lastName);
            registrationModalData.phoneNumber.setValue(signInForm.phoneNumber);

            moveToStepTwoOrThree({
                phoneNumber: signInForm.phoneNumber,
                lastName: signInForm.lastName,
            });

            setSignInForm({
                lastName: undefined,
                phoneNumber: undefined,
            });
        }
    }, [moveToStepTwoOrThree, signInForm, setSignInForm]);

    return (
        <>
            <Modal
                dialogClassName={modalDialogClassName}
                contentClassName={modalContentClassName}
                centered={true}
                show={true}
            >
                <Modal.Body className={Styles.RegistrationModal}>
                    <div
                        className={`px-4 py-3 d-flex align-items-center gap-2 ${Styles.ModalHeaderText}`}
                    >
                        <ShieldIcon />
                        <p className="m-0">
                            <a href="https://hitrustalliance.net/" className="" target={'_blank'}>
                                HITRUST
                            </a>{' '}
                            certified & HIPAA compliant
                        </p>
                    </div>
                    <Row
                        ref={StepZeroRef}
                        className={`gx-5 px-md-0 mx-0 ${Styles.ModalContentRow}`}
                    >
                        <Col
                            md={step === 1 ? '6' : '12'}
                            className="d-flex flex-column justify-content-between px-0"
                        >
                            <Row className={'mx-0'}>
                                <Col className={'px-0'}>
                                    {step >= 1 && step <= 3 ? (
                                        <StepBoard step={step} loading={loading} />
                                    ) : null}

                                    {loading && <StepOneLoading />}
                                    {!loading && step === 1 && (
                                        <StepOne formData={registrationModalData} />
                                    )}
                                    {!loading && step === 2 && (
                                        <StepTwo
                                            searchResults={searchResults}
                                            selectedContact={selectedContact}
                                            selectContact={selectContact}
                                            moveToStepFour={notInContact}
                                        />
                                    )}
                                    {!loading && step === 3 && (
                                        <StepThree
                                            member={selectedContact as MemberSearchResultModel}
                                        />
                                    )}
                                    {!loading && step === 4 && (
                                        <StepFour formData={registrationFormData} />
                                    )}
                                    {!loading && step === 5 && <StepFive />}
                                </Col>
                            </Row>

                            <Row className={'mx-0'}>
                                <Col className={'px-0'}>
                                    <StepActions
                                        step={step}
                                        onPrevious={previous}
                                        onNext={next}
                                        isNextButtonDisabled={isNextButtonDisabled}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        {step === 1 && (
                            <Col
                                md={step === 1 ? '6' : '12'}
                                className={`d-none d-md-block ${!stepZero ? 'd-none' : ''}`}
                            >
                                <Carousel />

                                <div
                                    className={`${
                                        width > 575 ? Styles.BgMainLight : ''
                                    } p-md-3 p-0 mt-3`}
                                >
                                    <h4
                                        className={`font-montserrat text-dark font-weight-normal ${Styles.ModalMissionTitleStepOne}`}
                                    >
                                        {TranslationService.getInstance().getPhrase(
                                            'sign_in.step_one.carousel.title'
                                        )}
                                    </h4>
                                    <p className={`text-base m-0 ${Styles.ModalDescription}`}>
                                        {TranslationService.getInstance().getPhrase(
                                            'sign_in.step_one.carousel.paragraph'
                                        )}
                                    </p>
                                    <div className={`text-center text-md-end w-100`}>
                                        <img
                                            src={Logo}
                                            alt="Wider Circle"
                                            className={`w-auto ${Styles.ModalSiteLogo}`}
                                        />
                                    </div>
                                    <Button
                                        type={ButtonType.LIGHT_BLUE}
                                        onClick={() => setStepZero(false)}
                                        customClassName={`d-sm-none px-4 py-3 mt-5 ${Styles.RegistrationModalButton}`}
                                    >
                                        Close
                                    </Button>
                                </div>
                            </Col>
                        )}
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};
