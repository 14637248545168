import { authenticationActions } from '@/authentication/reducer';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '@/store';
import { contactsActions } from '@/contacts/reducer';
import { eventsActions, fetchEvents } from '@/events/reducer';

const mapState = (state: RootState) => ({
    queryParameters: state.authentication.queryParameters,
    selectedContact: state.contacts.selectedContact,
    selectedEvent: state.events.selectedEvent,
    searchResults: state.contacts.searchResults,
    signInForm: state.contacts.signInForm,
});

const actionCreators = {
    storeAuthenticatedUser: authenticationActions.storeAuthenticatedUser,
    storeQueryParameters: authenticationActions.storeQueryParameters,
    storeSearchResults: contactsActions.storeSearchResults,
    selectContact: contactsActions.selectContact,
    storeMatchedContact: contactsActions.storeMatchedContact,
    setSignInForm: contactsActions.setSignInForm,
    fetchEvents,
    setClientNameFromAuthenticatedUser: eventsActions.updateClientFilter,
};

export const RegistrationModalConnector = connect(mapState, actionCreators);
export type RegistrationModalConnectorProps = ConnectedProps<typeof RegistrationModalConnector>;
