import React, { FunctionComponent, useCallback } from 'react';
import Styles from '../filters-modal/Styles.module.scss';

type Props = {
    label: string;
    value: string;
    isActive: boolean;
    isDisabled: boolean;
    onClick: (value: string) => void;
};

export const MobileFilterBadge: FunctionComponent<Props> = ({
    label,
    value,
    isActive,
    isDisabled,
    onClick,
}) => {
    const clickHandler = useCallback(() => {
        if (!isDisabled) {
            onClick(value);
        }
    }, [value, onClick, isDisabled]);

    return (
        <div
            className={`${Styles.MobileFilterBadge} ${
                isActive && Styles.Active
            } ${isDisabled && !isActive ? Styles.Disabled : null} py-2 px-4 d-flex justify-content-center align-items-center`}
            onClick={clickHandler}
        >
            <span>{label}</span>
        </div>
    );
};
